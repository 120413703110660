<template>
  <lf-modal :title="title" :close="onDismiss" small>
    <template #content>
      <div class="p-5 border-b space-y-4">
        <lf-h3>{{ title }}</lf-h3>
        <div v-if="!isNew && hasSubtitle" class="my-2">
          {{ $t("DEALS.SENTILINK.MATCH.STATUS_CHANGED") }}
        </div>
        <label v-if="hasManual" class="font-medium flex">
          <input
            v-model="isManual"
            type="checkbox"
            class="form-checkbox h-4 w-4 border-gray-300 rounded-sm place-self-center mr-4 cursor-pointer text-indigo-600"
            name="isManual"
            role="checkbox"
            data-cy="checkbox"
          />
          {{ $t("DEALS.SENTILINK.MATCH.MANUAL_INPUT") }}
        </label>
        <div v-if="!isManual" class="my-4 px-4 py-2 border rounded">
          <div class="uppercase text-xxs mb-1">
            {{ fieldTitle }}
          </div>
          <div
            class="text-gray-600"
            data-cy="update-record-new-value"
            v-html="highlightDiff(newValue, oldValue)"
          />
        </div>
        <div v-else class="mt-4">
          <lf-input
            class="h-14 mb-4"
            :placeholder="fieldName"
            :value="manualInput"
            name="manualInput"
            @keyup="handleManualChange"
          />
        </div>
        <div v-if="!isNew" class="px-4 py-2 border rounded">
          <div class="uppercase text-xxs mb-1">
            {{ $t("DEALS.SENTILINK.MATCH.APPLICATION_DATA") }}
          </div>
          <div
            class="text-gray-600"
            data-cy="update-record-old-value"
            v-html="highlightDiff(oldValue, newValue)"
          />
        </div>
        <slot />
      </div>
      <div class="p-5 flex justify-end space-x-4">
        <color-button type="unselected" @click.stop="onDismiss">
          {{ $t("COMMON.DISMISS") }}
        </color-button>
        <primary-button @click.stop="onConfirm">
          {{ isNew ? $t("COMMON.ADD_NEW_RECORD") : $t("COMMON.UPDATE_RECORD") }}
        </primary-button>
      </div>
    </template>
  </lf-modal>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import { computed, ref } from "vue";

import { wordsFirstLetterToUpper } from "@/helpers/formatting";
import type { IUpdateRecordModel } from "@/models/applications";
import { NA } from "@/helpers/constants";
import LfModal from "@/components/ui/Modal.vue";

const props = defineProps({
  title: {
    type: String,
    default: ""
  },
  fieldTitle: {
    type: String,
    default: ""
  },
  model: {
    type: Object as PropType<IUpdateRecordModel>,
    required: true
  },
  onConfirm: {
    type: Function as PropType<() => void>,
    required: true
  },
  onDismiss: {
    type: Function as PropType<() => void>,
    required: true
  },
  isNew: {
    type: Boolean,
    default: false
  },
  hasSubtitle: {
    type: Boolean,
    default: true
  },
  hasManual: {
    type: Boolean,
    default: false
  },
  fieldName: {
    type: String,
    default: ""
  }
});

const emit = defineEmits<{
  (e: "updateManual", value: string): void;
}>();

const isManual = ref(false);

const newValue = computed(() =>
  isManual.value
    ? manualInput.value
    : wordsFirstLetterToUpper(String(props.model.newValue).toLowerCase())
);

const oldValue = computed(() =>
  props.model.oldValue
    ? wordsFirstLetterToUpper(String(props.model.oldValue).toLowerCase())
    : NA
);

const manualInput = ref<string>(oldValue.value !== "N/A" ? oldValue.value : "");

const highlightDiff = (source: string | number, target: string | number) => {
  if (props.isNew) {
    return props.model.newValue;
  }
  const sourceString = String(source);
  const targetString = String(target);
  // simple comparison, makes assumptions that strings provided are similar
  // opted for this rather than pulling in a diffing library since it
  // should be sufficient for our needs without added bloat
  const compareLength =
    sourceString.length > targetString.length
      ? sourceString.length
      : targetString.length;

  const comparisonResult = document.createElement("span");
  let highlighted = null;
  let normal = null;

  for (let i = 0; i < compareLength; i++) {
    if (sourceString.charAt(i) !== targetString.charAt(i)) {
      if (normal !== null) {
        comparisonResult?.appendChild(normal);
        normal = null;
      }

      if (highlighted !== null) {
        highlighted.innerHTML += sourceString.charAt(i);
      } else {
        highlighted = document.createElement("span");
        highlighted?.classList.add("bg-red-100");
        highlighted.innerHTML = sourceString.charAt(i);
      }
    } else {
      if (highlighted !== null) {
        comparisonResult?.appendChild(highlighted);
        highlighted = null;
      }
      if (normal !== null) {
        normal.innerHTML += sourceString.charAt(i);
      } else {
        normal = document.createElement("span");
        normal.innerHTML = sourceString.charAt(i);
      }
    }

    if (highlighted !== null) {
      comparisonResult?.appendChild(highlighted);
    }

    if (normal !== null) {
      comparisonResult?.appendChild(normal);
    }
  }

  return comparisonResult.innerHTML;
};

const handleManualChange = (e: KeyboardEvent) => {
  manualInput.value = (e.target as HTMLInputElement)?.value;
  emit("updateManual", newValue.value);
};
</script>
